<template>
    <div class="intro">
        <img src="static/apple-icon.png" alt=""/>
        欢迎来到 <b>Demo推理馆</b> 专用血染钟楼！
        <br>若您是说书人：请点击右上角<span class="button" @click="toggleMenu"><font-awesome-icon icon="cog"/> 菜单</span>
        或按 <b>[H]</b> 创建房间 再并按 [A] 添加座位。
        <br>若您是玩家：请点击右上角<span class="button" @click="toggleMenu"><font-awesome-icon icon="cog"/> 菜单</span>
        或按 <b>[J]</b> 加入房间。
        <div class="footer">
            <h4>欢迎各位来线下找我们玩耍，我们期待结识更多的小伙伴（老板单身！）。</h4>
            <address>
                <ul>
                    <li>
                        Demo沉浸式互动推理馆
                    </li>
                    <li>
                        <a target="_blank" href="https://map.baidu.com/poi/Demo%E6%B2%89%E6%B5%B8%E5%BC%8F%E4%BA%92%E5%8A%A8%E6%8E%A8%E7%90%86%E9%A6%86/@13523995,3647794,13z?uid=cff02f2e806a43b149fc8d16&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl">上海市虹口区东江湾路188号D栋三楼326</a>
                    </li>
                    <li>
                        微信：shindou_jay
                    </li>
                </ul>
            </address>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    methods: mapMutations(["toggleMenu"])
};
</script>

<style scoped lang="scss">
// Intro
.intro {
    text-align: center;
    width: 50%;
    font-size: 120%;
    position: absolute;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: 3px solid black;
    border-radius: 10px;
    z-index: 3;

    a {
        color: white;
    }

    img {
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 25vh;
        margin-left: -12.5vh;
        margin-bottom: 2vh;
        max-width: 192px;
        border-radius: 50%;
        box-shadow: 0 0 10px black;
        border: 3px solid black;
    }

    .footer {
        font-size: 60%;
        opacity: 0.75;
    }
}
</style>
